import { Grid, Stack } from "@mui/material";
import { IdmClientContext } from "@sista/idm-client";
import {
  Organization,
  Relation,
  RelationDTO,
  Right,
  SistaId,
  SmartButton,
  useMaxLengthValidation,
  useMinLengthValidation,
  useRequiredValidation,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useIcoValidation } from "@sista/library-stasi/dist/components/form/validations/useIcoValidation";
import { SistaChip } from "@sista/sista-chip";
import { t } from "i18next";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";

export type OrganizationRegistrationForm = {
  idNumber: string;
};

export type OrganizationSearchByIcoContentProps = {
  onFoundOrganization: (organization: Organization) => void;
  onFoundRelationsInOrganization: (relations: RelationDTO[]) => void;
};

export const OrganizationSearchByIcoContent = (
  props: OrganizationSearchByIcoContentProps
) => {
  const ico = useIcoValidation();
  const maxLength = useMaxLengthValidation(8);
  const minLength = useMinLengthValidation(8);
  const required = useRequiredValidation();

  const { loggedAccount } = useContext(IdmClientContext);

  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>(null);

  const {
    getOrCreateOrganizationByIcoWithForceRefresh,
    getMyRelationsInOrganization,
  } = useRejstrikClient();

  const [
    getOrCreateOrganizationByIcoWithForceRefreshCall,
    getOrCreateOrganizationByIcoWithForceRefreshState,
  ] = useSafeSistaAsync(getOrCreateOrganizationByIcoWithForceRefresh);

  const [getMyRelationsInOrganizationCall, getMyRelationsInOrganizationState] =
    useSafeSistaAsync(getMyRelationsInOrganization);

  const submitForm = useCallback(
    async (formData: OrganizationRegistrationForm) => {
      getOrCreateOrganizationByIcoWithForceRefreshCall({
        ico: formData.idNumber,
        forceRefresh: true,
      });
    },
    [getOrCreateOrganizationByIcoWithForceRefreshCall]
  );

  const form = useForm<OrganizationRegistrationForm, unknown>({
    defaultValues: {
      idNumber: "",
    },
  });

  useEffect(() => {
    if (getOrCreateOrganizationByIcoWithForceRefreshState.value != null) {
      getMyRelationsInOrganizationCall({
        organizationId:
          getOrCreateOrganizationByIcoWithForceRefreshState.value.id,
      });
    }
  }, [
    getMyRelationsInOrganizationCall,
    getOrCreateOrganizationByIcoWithForceRefreshState.value,
  ]);

  useEffect(() => {
    if (getMyRelationsInOrganizationState.value != null) {
      props.onFoundOrganization(
        getOrCreateOrganizationByIcoWithForceRefreshState.value
      );
      props.onFoundRelationsInOrganization(
        getMyRelationsInOrganizationState.value
      );
    }
  }, [
    getMyRelationsInOrganizationState.value,
    getOrCreateOrganizationByIcoWithForceRefreshState.value,
    props,
  ]);

  const ic = form.watch("idNumber");
  const urnIc = useMemo(() => {
    return new SistaId("organizations:ico", ic).asUrn();
  }, [ic]);

  return (
    <FormContainer onSuccess={submitForm} formContext={form}>
      <Stack direction="column" spacing={2} mt={2}>
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          <Grid item xs={12} sm={4}>
            <TextFieldElement
              name={"idNumber"}
              autoComplete={"off"}
              label={t("ucet.organization.form.idNumber", "IČ")}
              validation={{
                ...required,
                ...maxLength,
                ...minLength,
                ...ico,
              }}
            />
          </Grid>
          {ic && ic.length > 0 && ico.validate(ic) == undefined && (
            <Grid item xs={12} sm={8}>
              <SistaChip
                urn={urnIc}
                authToken={loggedAccount.authKey}
                variant="small"
              />
            </Grid>
          )}
        </Grid>
        <Right sx={{ marginTop: 4 }}>
          <SmartButton
            state={getOrCreateOrganizationByIcoWithForceRefreshState}
          >
            {t("ucet.organization.form.search", "Vyhledat organizaci")}
          </SmartButton>
        </Right>
      </Stack>
    </FormContainer>
  );
};
